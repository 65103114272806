$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  nv: 725px,
  //custom nav-bar breakpoint
  md: 1024px,
  lg: 1270px,
  xl: 1900px,
);

$container-max-widths: (
  sm: 540px,
  md: 1000px,
  lg: 1140px,
  xl: 1610px,
);

@import "node_modules/bootstrap/scss/bootstrap";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

@import "./variables.scss";

:root {
  --app-height: 100%;
}

html,
body {
  font-family: "Lato", sans-serif;
  background: #ecedef;
  font-size: 14px;
  margin: 0;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}

.hidden {
  display: none !important;
}

.row-container {
  display: flex;
  flex-direction: row;
}

b {
  color: #02f1a0;
}

#app {
  overflow: auto;
  height: 100%;
  background-color: $immerso-blue-color;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.logo {
  cursor: pointer;
}

.platform {
  background: $immerso-blue-color;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.textbtn {
  font-size: 1.3rem;
  line-height: 28px;
  color: #fff;
  background: none;
  border: none;
  font-weight: normal;
  padding: 0.5em;
  font-family: "Eina02-Bold";
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #02f1a0;
    border-radius: 1em;
    text-decoration: none;
  }
}

.platformNavbar {
  width: 100%;
  height: 15vh;
  padding-bottom: 9vh;
  padding-top: 5vh;
  color: #828797;
  display: flex;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
  z-index: 90;
  background: -moz-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 30%,
    transparent 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(12, 19, 44, 1),
    rgba(12, 19, 44, 0)
  );

  .navbarContent {
    width: 100%;
    max-width: 1140px;

    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    flex-wrap: wrap;
    flex-flow: row;

    .nav-item {
      align-self: center;
    }
  }
}

#navbarNav {
  margin-left: 5vw;
}

.nav-container {
  color: #fff;
}

.logo {
  position: relative;
  left: 0;
  width: auto;
  height: 2em;
}

.profileImage {
  cursor: pointer;

  &:hover {
    color: #02f1a0;
  }

  .profileLogo {
    height: 80%;
    width: auto;
  }
}

.experienceFooter {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin: 2.5rem 0 2.5rem 0;
  position: relative;
  bottom: 0;
  left: 0;
  background-color: $immerso-blue-color;
  color: #fff;
  display: flex;

  border-radius: 1em 1em 0 0;

  .footerContent {
    width: 100%;
    max-width: 1140px;
    display: flex;

    justify-content: space-between;
    align-items: center;
    align-self: end;

    .logo {
      margin-bottom: 1.5rem;
      height: 32px;
      width: auto;

      @media (max-width: 700px) {
        height: 20px;
      }
    }

    .footer-text {
      margin-left: 1rem;
      font-size: 1.2rem;
      font-weight: 200;
      font-family: "Eina", Sans-serif;

      @media (max-width: 700px) {
        font-size: 0.8rem;
      }

      @media (max-width: 400px) {
        font-size: 0.5rem;
      }
    }

    .flags {
      margin-left: 5em;
      width: 4em;
      height: 2em;
    }

    .icons {
      a {
        i,
        svg {
          color: #fff;
          font-size: 4em;
          margin-right: 0.5em;
        }
      }

      margin-right: 3.5rem;

      @media (max-width: 700px) {
        a {
          i,
          svg {
            font-size: 2em;
            margin-right: 0.5em;
          }
        }

        margin-right: 0rem;
      }

      @media (max-width: 400px) {
        a {
          i,
          svg {
            font-size: 2em;
            margin-right: 0.1em;
          }
        }

        margin-right: 0.1rem;
      }
    }
  }
}

.explore {
  background: $immerso-blue-color;
  position: relative;
  width: 100%;
  max-width: 100%;

  .header {
    width: 100%;
    height: 70vh;
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 70%,
      transparent 100%
    );
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(70%, rgba(0, 0, 0, 1)),
      color-stop(100%, rgba(0, 0, 0, 0))
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    text-align: center;

    .title {
      position: relative;
      top: 40%;
      font-family: "Eina02-Bold";
      font-size: 6em;
      color: #fff;
      padding: 0 0.1em 0 0.1em;
      text-shadow: 2.5px 2.5px 10px black;

      @media (min-width: 400px) and (max-width: 800px) {
        font-size: 5em;
      }

      @media (max-width: 400px) {
        font-size: 4em;
      }
    }
  }

  .experienceShow {
    margin-top: 3em;
  }
}

.experienceCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0 0.8em;
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  width: 245px;
  height: 396px;
  color: #202945;
  box-sizing: border-box;

  &.comming-soon {
    opacity: 0.8;
  }

  button {
    pointer-events: none;
    width: 8em;
    height: 2em;
    border: none;
    border-radius: 13px;
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    background: #2cff7d;
    text-align: center;
    font-weight: bolder;

    &.comming-soon {
      background: #ff736f;
    }
  }
}

.cardTitle {
  font-weight: 900;
  margin-top: 10px;
  font-size: 1.2em;
  text-align: initial;
  margin-bottom: 0rem;
}

.cardLocation {
  text-align: initial;
  font-style: italic;
  font-size: 1em;
}

.cardImage {
  width: inherit;
  max-width: 120%;
  height: 16em;
  border-radius: 10px 10px 0px 0px;
  align-self: center;
  margin-top: -1px;
}

.labelsCard {
  color: $immerso-blue-color;
  background-color: #ecedef;
  border: 0;
  border-radius: 15px;
  width: 106px;
  height: 35px;
  padding: 0.7em;
  text-align: center;
  font-size: 13px;
  margin-right: 0.5em;
}

.labelsCardContainer {
  display: flex;
  text-transform: capitalize;
}

.login,
.register {
  min-width: 384px;
  width: 60%;
  max-width: 60%;
  margin-top: 14vh;
  margin-left: auto;
  margin-right: auto;

  form {
    padding: 1rem;
    background: #fff;
    border: 1px solid;
    border-color: lightgray;
    border-radius: 2em;
  }

  .disabled-button {
    width: 116%;
    height: 37px;
    background: white;
    border-radius: 8px !important;
    font-style: normal;
    font-size: larger;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    border: solid;
    border-width: 2px;
    border-color: #202945;
    opacity: 1;
  }

  .enabled-button {
    width: 116%;
    height: 37px;
    border-radius: 8px !important;
    font-style: normal;
    font-size: larger;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    border: none;
  }

  .enabled-button:hover {
    background-color: #20ba7e;
  }

  label {
    width: 80%;
    margin-left: -33%;

    b {
      font-size: 14px;
      line-height: 19px;

      /* immersoB */

      color: #202945;
    }
  }

  .container {
    display: flex;
    align-content: center;
    align-items: center;
    width: 50%;
  }

  .separator {
    width: 59%;
    border: 1px solid lightgray;
    margin-left: 20%;
    margin-bottom: 13px;
  }

  .button {
    width: 116%;
    height: 37px;
    /* immersoV */

    background-color: #00f0a1;
    border-radius: 0.8em;

    font-style: normal;
    font-size: larger;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    border: none;
    /* immersoB */

    color: #202945;
  }

  input {
    margin-bottom: 2rem;
    width: 116%;
    height: 31px;
    border-radius: 8px;
    border: outset;
    border-color: lightgray;
    border-width: 2px;
  }

  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;

    h1 {
      font-weight: bolder;
      margin-top: 4px;
    }

    a {
      color: #00f0a1;
      margin-left: 0.5rem;
      font-weight: bolder;
    }

    .link {
      font-size: 16px;
      line-height: 19px;

      /* immersoB */

      color: #202945;
    }

    h1 {
      font-size: 36px;
      line-height: 42px;

      /* immersoB */

      color: #202945;
    }
  }

  .buttonGoogle {
    color: #202945 !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.24) 3px 5px 1px 0px !important;
    border: none !important;
    border-radius: 0.8em !important;
    width: 116%;
    height: 3.8em;
    background-color: #00f0a1 !important;
    font-family: "Lato" !important;

    svg {
      overflow: auto;
      margin-top: -1em;
      margin-left: -0.3em;
    }

    span {
      margin-left: -3%;
      margin-left: auto;
      margin-right: auto;
      font-size: larger;
      font-weight: 600 !important;
    }

    div {
      margin-left: 1em;
      width: 2em;
      height: 2em;
    }
  }

  .buttonGoogle:hover {
    background-color: #20ba7e !important;
  }

  .errors {
    margin-top: -1rem;
    color: #ff0000;
    text-align-last: center;
    margin-bottom: 1rem;
  }
}

// XEngine

#xengine {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: contents;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.xengine-ui {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  .notification {
    z-index: 1;
    opacity: 0;
    background: #202945;
    padding: 1rem 2rem 1rem 2rem;
    color: #fff;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 1rem;
    left: 7rem;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 3rem;
    transition: opacity 1s;

    @media (max-width: 823px) {
      padding: (1rem * $xengine-responsive-quotient)
        (2rem * $xengine-responsive-quotient)
        (1rem * $xengine-responsive-quotient)
        (2rem * $xengine-responsive-quotient);
      left: 8rem * $xengine-responsive-quotient;
      font-size: 2rem * $xengine-responsive-quotient;
    }

    &.showing {
      opacity: 1;
    }
  }

  .menu-line {
    height: 3px;
    width: 1rem;
    background-color: #00f0a1;
    z-index: 1;

    @media (min-width: 410px) and (max-width: 823px) {
      width: 0.5rem;
    }

    @media (min-width: 348px) and (max-width: 410px) {
      width: 0.5rem;
    }

    @media (max-width: 348px) {
      width: 0.4rem;
    }
  }

  .menu-btn-container {
    position: fixed;
    bottom: 0rem;
    left: 16px;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 4;
    user-select: none;

    @media (min-width: 410px) and (max-width: 823px) {
      margin-bottom: 1rem;
      left: 6px;
    }

    @media (min-width: 348px) and (max-width: 410px) {
      margin-bottom: 1rem;
      left: 8px;
    }

    @media (max-width: 348px) {
      margin-bottom: 1rem;
      left: 6px;
    }
  }

  .menu-btn-container-r {
    position: fixed;
    bottom: 11rem;
    right: 0;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 4;
    user-select: none;

    @media (max-width: 823px) {
      margin-bottom: -8rem * $xengine-responsive-quotient;
    }
  }

  .menu-participant-counters-container {
    position: fixed;
    left: 0;
    bottom: 20px;
    margin-bottom: 7rem;
    margin-left: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
    user-select: none;

    & span {
      padding-left: 0;
    }

    @media (min-width: 410px) and (max-width: 823px) {
      & {
        margin-left: 3rem;
      }

      & a {
        position: fixed;
        bottom: 45px;
        margin-left: 1rem;
        margin-bottom: 4rem;
        margin-right: -2.5em;
      }
    }

    @media (min-width: 348px) and (max-width: 410px) {
      & {
        margin-left: 3rem;
      }

      & a {
        position: fixed;
        bottom: 37px;
        margin-left: 1rem;
        margin-bottom: 4rem;
        margin-right: -1.3em;
      }
    }

    @media (max-width: 348px) {
      & {
        margin-left: 3rem;
      }

      & a {
        position: fixed;
        bottom: 40px;
        margin-left: 1rem;
        margin-bottom: 2.5rem;
        margin-right: -2em;
      }
    }

    & span {
      writing-mode: lr;
      font-size: medium;
    }

    & .xengine-btn {
      cursor: pointer;
      height: 5.5rem;
      width: 5.5rem;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      z-index: 2;
      background-color: #202945;
      border-radius: 50%;
      border: 0.25rem solid #00f0a1;
      writing-mode: tb-rl;

      @media (min-width: 410px) and (max-width: 823px) {
        height: 8rem * $xengine-responsive-quotient;
        width: 8rem * $xengine-responsive-quotient;
        font-size: 2.8rem * $xengine-responsive-quotient;
      }

      @media (min-width: 348px) and (max-width: 410px) {
        height: 7rem * $xengine-responsive-quotient;
        width: 7rem * $xengine-responsive-quotient;
        font-size: 2.4rem * $xengine-responsive-quotient;

        & span {
          font-size: smaller;
        }
      }

      @media (max-width: 348px) {
        height: 6rem * $xengine-responsive-quotient;
        width: 6rem * $xengine-responsive-quotient;
        font-size: 2rem * $xengine-responsive-quotient;

        & span {
          font-size: small;
        }
      }
    }
  }

  .menu-video-btn-container {
    position: fixed;
    top: 1em;
    right: 1em;
    margin-bottom: 36rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
    user-select: none;
  }

  .xengine-menu {
    position: fixed;
    bottom: 0rem;
    left: 86px;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 6rem;
    transition: left 0.5s;
    margin-bottom: 2rem;
    user-select: none;

    @media (min-width: 410px) and (max-width: 823px) {
      padding-right: 6rem * $xengine-responsive-quotient;
      margin-bottom: 2rem * $xengine-responsive-quotient;
      margin-right: 1.1rem;
      left: 49px;
    }

    @media (min-width: 348px) and (max-width: 410px) {
      padding-right: 6rem * $xengine-responsive-quotient;
      margin-bottom: 2rem * $xengine-responsive-quotient;
      margin-right: 2%;
      left: 42px;
    }

    @media (max-width: 348px) {
      padding-right: 6rem * $xengine-responsive-quotient;
      margin-bottom: 2rem * $xengine-responsive-quotient;
      margin-right: -1%;
      left: 40px;
    }

    &.menu-hidden {
      left: -60rem;
    }
  }

  .xengine-menu-reaction {
    position: fixed;
    bottom: 11rem;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 6rem;
    transition: right 0.5s;
    margin-bottom: 2rem;
    user-select: none;

    @media (min-width: 410px) and (max-width: 823px) {
      padding-right: 6rem * $xengine-responsive-quotient;
      margin-bottom: -8rem * $xengine-responsive-quotient;
      margin-right: 1.1rem;
    }

    @media (min-width: 348px) and (max-width: 409px) {
      padding-right: 6rem * $xengine-responsive-quotient;
      margin-bottom: -8rem * $xengine-responsive-quotient;
      margin-right: 2%;
    }

    @media (max-width: 348px) {
      padding-right: 6rem * $xengine-responsive-quotient;
      margin-bottom: -7.4rem * $xengine-responsive-quotient;
      margin-right: 1%;
    }

    &.menu-hidden-r {
      right: -60rem;
    }
  }

  .xengine-reactions {
    position: fixed;
    bottom: 17rem;
    right: 0;
    z-index: 3;
    display: block;
    justify-content: flex-end;
    align-items: center;
    transition: right 0.5s;

    @media (min-width: 410px) and (max-width: 823px) {
      bottom: 16rem;
      margin-right: 0em;
    }

    @media (min-width: 348px) and (max-width: 410px) {
      bottom: 16.5rem;
      margin-right: 0em;
    }

    @media (max-width: 348px) {
      bottom: 12.5rem;
      margin-right: 0em;
    }

    &.menuReactions-hidden {
      right: -10rem;
    }
  }

  .xengine-btn {
    cursor: pointer;
    height: 5rem;
    width: 5rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    z-index: 3;
    background-color: #202945;
    border-radius: 50%;
    border: 0.25rem solid #00f0a1;
    writing-mode: tb-rl;

    @media (min-width: 600px) and (max-width: 930px) {
      height: 87px * $xengine-responsive-quotient;
      width: 87px * $xengine-responsive-quotient;
      font-size: 3rem * $xengine-responsive-quotient;
    }

    @media (min-width: 525px) and (max-width: 600px) {
      height: 70px * $xengine-responsive-quotient;
      width: 70px * $xengine-responsive-quotient;
      font-size: 3rem * $xengine-responsive-quotient;
    }

    @media (max-width: 525px) {
      height: 68px * $xengine-responsive-quotient;
      width: 68px * $xengine-responsive-quotient;
      font-size: 1.8rem * $xengine-responsive-quotient;
    }

    &.menu-video {
      z-index: 3;
      width: 1.5em;
      height: 1.5em;
      margin-right: 0rem;
    }

    img {
      width: 1em;
      user-select: none;
    }
  }

  .menu-static-container {
    position: fixed;
    bottom: 0rem;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    user-select: none;

    @media (min-width: 410px) and (max-width: 823px) {
      margin-bottom: 2rem * $xengine-responsive-quotient;
    }

    @media (min-width: 357px) and (max-width: 410px) {
      margin-bottom: 2rem * $xengine-responsive-quotient;
      bottom: 0;
    }

    @media (max-width: 357px) {
      margin-bottom: 2rem * $xengine-responsive-quotient;
      bottom: 0;
    }

    a:hover {
      text-decoration: none;
      color: black;
    }
  }

  .xengine-static-btn {
    cursor: pointer;
    height: 5rem;
    width: 5rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    z-index: 3;
    background-color: #202945;
    border-radius: 50%;
    border: 0.25rem solid;
    writing-mode: tb-rl;
    margin-left: 0.8rem;
    height: 70px;
    max-height: 15vh;
    width: 70px;
    max-width: 15vh;
    font-size: 2rem;
    margin: 0 0.5rem 0 0.5rem;

    // @media (min-width:600px)and (max-width: 930px) {
    //   height: 87px * $xengine-responsive-quotient;
    //   width: 87px * $xengine-responsive-quotient;
    //   font-size: 3rem * $xengine-responsive-quotient;
    //   margin-left: 0.5rem;
    // }
    // @media (min-width:525px)and (max-width: 600px) {
    //   height: 70px * $xengine-responsive-quotient;
    //   width: 70px * $xengine-responsive-quotient;
    //   font-size: 3rem * $xengine-responsive-quotient;
    //   margin-left: 0.5rem;
    // }
    // @media (max-width: 525px) {
    //   height: 87px * $xengine-responsive-quotient;
    //   width: 87px * $xengine-responsive-quotient;
    //   font-size: 1.8rem * $xengine-responsive-quotient;
    //   margin-left: 0.6rem;
    // }
    @media (max-width: 525px) {
      width: 60px;
      height: 60px;
    }

    img {
      width: 1em;
      user-select: none;
    }

    &.autoOn {
      writing-mode: horizontal-tb;
      font-size: 1.4rem;
      font-weight: bolder;
      background-color: #00f0a1;
    }

    &.autoOff {
      writing-mode: horizontal-tb;
      font-size: 1.4rem;
      font-weight: bolder;
    }

    &.mute {
      z-index: 3;
      color: white;
      background-color: $immerso-blue-color;
      border-radius: 50%;
      border: 0.25rem solid #00f0a1;
      writing-mode: vertical-lr;
    }

    &.unmute {
      z-index: 3;
      color: white;
      background-color: #00f0a1;
      border-radius: 50%;
      border: 0.25rem solid #00f0a1;
      writing-mode: vertical-lr;
    }
  }

  .menu-btn-text {
    padding-left: 0.3rem;

    @media (min-width: 410px) and (max-width: 823px) {
      padding-left: 0.1rem;
      font-size: 0.9rem;
      font-weight: bold;
    }

    @media (min-width: 348px) and (max-width: 410px) {
      font-size: 0.8rem;
      font-weight: bold;
    }

    @media (max-width: 348px) {
      padding-right: 3px;
      font-size: 0.7rem;
      font-weight: bold;
    }
  }

  .xengine-btn-reactions {
    cursor: pointer;
    height: 3.5rem;
    width: 5rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    z-index: 3;
    text-shadow: 0em 2.5px 5px black;

    @media (min-width: 410px) and (max-width: 823px) {
      height: 84px * $xengine-responsive-quotient !important;
      width: 84px * $xengine-responsive-quotient !important;
      font-size: 3rem * $xengine-responsive-quotient;
      margin-right: 1.2em * $xengine-responsive-quotient;
      margin-bottom: 0.7em;
    }

    @media (min-width: 348px) and (max-width: 410px) {
      height: 70px * $xengine-responsive-quotient;
      width: 70px * $xengine-responsive-quotient;
      font-size: 3rem * $xengine-responsive-quotient;
      margin-right: 1em * $xengine-responsive-quotient;
      margin-top: 0.5em;
    }

    @media (max-width: 348px) {
      height: 68px * $xengine-responsive-quotient !important;
      width: 68px * $xengine-responsive-quotient !important;
      font-size: 1.8rem * $xengine-responsive-quotient;
      margin-right: 1em * $xengine-responsive-quotient !important;
      margin-bottom: 1em;

      img {
        width: 3em !important;
        user-select: none;
      }
    }

    img {
      width: 2em;
      user-select: none;
    }

    &.menu-reaction {
      z-index: 3;
      width: 1.5em;
      height: 1.5em;
      margin-right: 1rem;
      background-color: #202945;
      border-radius: 50%;
      border: 0.25rem solid #00f0a1;
      writing-mode: vertical-lr;
    }
  }

  .xengine-guide-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    position: fixed;
    top: 0rem;
    right: 0;
    margin-right: 2em;
    margin-top: 2em;
    z-index: 4;

    &.circle {
      img {
        border-radius: 50%;
      }
    }

    img {
      z-index: 3;
      max-width: 10em;
      max-height: 10em;

      @media (min-width: 601px) and (max-width: 812px) {
        width: 6em;
        height: 6em;
      }

      @media (min-width: 401px) and (max-width: 600px) {
        width: 5em;
        height: 5em;
      }

      @media (max-width: 400px) {
        width: 4em;
        height: 4em;
      }
    }

    @media (min-width: 401px) and (max-width: 600px) {
      margin-right: 2em;
      margin-top: 2em;
    }

    @media (max-width: 400px) {
      margin-right: 1em;
      margin-top: 1em;
    }

    .btnAudioHandler {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 4em;
      height: 4em;
      z-index: 4;
      pointer-events: all;
      cursor: pointer;
      visibility: hidden;

      @media (min-width: 601px) and (max-width: 812px) {
        width: 2.5em;
        height: 2.5em;
      }

      @media (min-width: 401px) and (max-width: 600px) {
        width: 2em;
        height: 2em;
      }

      @media (max-width: 400px) {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}

.animationAudioLocationHandler {
  #image-guide-photo {
    animation-name: animationAudioLocation;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-fill-mode: forwards;

    @keyframes animationAudioLocation {
      to {
        filter: drop-shadow(0px 0px 10px #00f0a1);
      }

      from {
        filter: drop-shadow(0px 0px 20px #00f0a1);
      }

      to {
        -webkit-filter: drop-shadow(0px 0px 10px #00f0a1);
      }

      from {
        -webkit-filter: drop-shadow(0px 0px 20px #00f0a1);
      }
    }
  }
}

@keyframes example {
  0% {
    top: 100px;
  }

  100% {
    background: none;
    top: 0px;
  }
}

//Contact Page

.contactPage {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 2rem;
  margin-top: 14vh;
}

.contactPageColumn {
  display: flex;
  width: 50%;
  flex-direction: column;

  h1 {
    margin-top: 10%;
    font-weight: 600;
    font-size: xxx-large;
    text-align: center;
    color: #202945;
  }

  span {
    color: #202945;
    text-align: -webkit-center;
    font-size: normal;
    text-align: -webkit-center;
    -webkit-print-color-adjust: initial;
  }

  &.content {
    padding: 1.5em;
  }
}

.video-hidden {
  display: none;
}

.imagIcono {
  width: 5em;
  height: auto;
  text-align: center;
  margin-left: 3em;
  margin-right: 3em;
}

.iconsContactPage {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-self: center;
  padding: 4em;
}

.buttonContact {
  border-radius: 2em;
  background-color: #00f0a1;
  border-color: #00f0a1;
  font-size: x-large;
  font-weight: 400;
  color: #202945;
  margin-top: 2em;
}

.imageContact {
  width: 90%;
  height: auto;
  margin-top: 3em;
  display: flex;
  margin-left: 6em;
}

.xengine-btn:hover,
.xengine-static-btn:hover {
  background-color: #00f0a1;
}

.xengine-header {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 4rem;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
  user-select: none;
}

.xengine-location-name {
  cursor: default;
  padding: 0.2rem 1rem 0 1rem;
  border-radius: 0 0 2.5rem 2.5rem;
  // border-bottom: 0.3rem solid #00f0a1;
  // border-left: 0.3rem solid #00f0a1;
  // border-right: 0.3rem solid #00f0a1;
  font-size: 2rem;
  font-weight: bold;

  @media (max-width: 812px) {
    height: 5rem * $xengine-responsive-quotient;
    font-size: 2rem * $xengine-responsive-quotient;
    padding: (0.2rem * $xengine-responsive-quotient)
      (1rem * $xengine-responsive-quotient) 0
      (1rem * $xengine-responsive-quotient);
    border-radius: 0 0 (2.5rem * $xengine-responsive-quotient)
      (2.5rem * $xengine-responsive-quotient);
  }
}

.experienceInfo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 14vh 0 2em 0;
  min-height: 82vh;
  white-space: pre-wrap;
}

.experienceColumn {
  position: relative;

  border-radius: 1rem;
  margin: 0.5rem;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  width: 100%;
  flex-direction: column;
  // padding: 2em 2em 7em 2em;
  padding: 2em 2em 2em 2em;

  button {
    margin-top: 1rem;
    font-size: 1.8rem !important;
    font-weight: bold;

    &.final-btn {
      position: absolute;
      bottom: 0;
      margin-left: auto;
      margin-bottom: 1rem;
    }
  }

  .info-container {
    max-width: 800px;
    letter-spacing: 1px;
    font-size: 1.1em;
    line-height: 24px;

    .info-location {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        color: $immerso-blue-color;
        margin: 0;
      }

      i {
        color: $immerso-blue-color;
        margin: 0.5em;
        font-size: 1.5em;
      }
    }
  }

  .iframe-box {
    margin: 1rem 0 1rem 0;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.3%;

    /* This is your aspect ratio */
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    font-weight: 700;
    text-align: center;
    font-size: 3em;
    width: 100%;
  }

  button {
    align-self: center;
    border: none !important;
    border-radius: 0.5em;
    font-size: 1.3em;
    padding: 1rem;

    @media (max-width: 1033px) {
      margin-top: 0.5em;
    }
  }

  .btn-experience {
    // background-color: #2cff7d;
    background-color: #00f0a1;
  }

  .btn-experience:hover {
    background-color: #02db93;
  }

  .site-page-btn {
    background-color: #dedfe3 !important;
  }

  .site-page-btn:hover {
    background-color: #cacbcf !important;
  }

  .col-12 {
    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .action-btn {
  }

  .site-page-btn {
    background-color: white;
    border: 2px solid black;
  }

  .date-container {
    width: auto;
    margin-left: 0%;

    a {
      color: black;
    }
  }

  .date-info {
    text-align: center;
    background-color: #ebebeb;
    border-radius: 12px;
    box-shadow: 0px 6px 5px #d2d2d2;
    padding: 5px;
    width: max-content;

    @media (max-width: 450px) {
      display: block;
      margin-bottom: 21px;
    }
  }

  .date-heading {
    font-size: x-large;
    font-weight: 900;
    margin-bottom: 2px;
  }

  .date-footer {
    margin-top: 10px;
  }
}

.textReset {
  padding-bottom: 0.3em;
  margin: 0.5em 0 0;
  line-height: 1.2;
  font-weight: 700;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 0.5rem !important;
}

.grecaptcha-badge {
  display: none;
}

.a-enter-vr {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 1rem) !important;
  height: calc(100vh - 1rem) !important;
  pointer-events: none;
}

.a-enter-vr-button {
  display: initial;
  pointer-events: all;
}

.a-enter-ar-button {
  display: initial;
}

.modalTitle {
  color: #00efa0;
  text-align: center;
}

.modalColumn {
  width: 50%;
  flex-direction: column;
  display: inline-flex;
  padding: initial;

  h1 {
    font-weight: 400;
    color: #202945;
    width: max-content;
    font-size: 160%;
  }

  span {
    color: #202945;
    text-align: left;
    width: 270%;
    font-size: smaller;
    padding-left: 6%;
  }

  button {
    width: 13em;
    height: auto;
    background-color: #00efa0;
    border-radius: 5em;
    border-color: #00efa0;
    align-self: center;
    margin-top: 2.5em;
  }
}

#modalID {
  top: 52% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
  font-family: Lato !important;
  overflow-y: auto !important;
  overflow-x: hidden;
  border-color: #00efa0 !important;
  border-width: thick !important;
  border-radius: 1em !important;
  width: 36rem !important;

  .modalContainer {
    margin-left: 3em;
    margin-right: 2em;
    flex-direction: column;
    display: inline-flex;
    padding: initial;
    font-size: medium;
    inline-size: 1.5;
  }

  .close-btn {
    padding-left: 90%;
    font-size: larger;
  }

  p {
    margin-bottom: 0.5;
    font-size: medium;
  }

  h1 {
    margin-bottom: 0.5em;
    font-size: large;
  }

  .bloques {
    display: inline-flex !important;
  }

  @media (max-width: 600px) {
    width: 60vw !important;
    height: 85vw !important;

    overflow-x: hidden !important;

    .modalContainer {
      margin-left: 1em !important;
      margin-right: 0.5em;
      margin-bottom: 1em;
      padding-right: 0 !important;
    }

    p {
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 1em;
      font-size: medium;
      max-width: 100%;
    }

    h1 {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0.75em;
      font-size: small;
      max-width: 100%;
    }
  }

  @media (max-width: 450px) {
    width: 75vw !important;
    height: 120vw !important;
    overflow-x: hidden !important;

    .modalContainer {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 1em !important;
      padding-right: 0 !important;
    }

    p {
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 1em;
      font-size: medium;
      max-width: 100%;
    }

    h1 {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0.75em;
      font-size: small;
      max-width: 100%;
    }
  }

  @media (max-width: 290px) {
    width: 70vw !important;
    height: 160vw !important;

    .modalContainer {
      margin-left: 0 !important;
      margin-right: 0.5em;
      margin-bottom: 1em;
      padding-right: 0 !important;
    }

    p {
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0.2em;
      font-size: smaller;
      max-width: 100%;
    }

    h1 {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0.2em;
      font-size: small;
      max-width: 100%;
    }
  }
}

.labels {
  margin-top: 1.55em;
  flex-direction: row;
  width: max-content;

  &.content {
    width: 10em;
    background-color: $immerso-white-color;
    border: 0;
    border-radius: 1em;
    padding: 0.55em 1em;
    text-emphasis: wrap;
    margin-right: 0.3em;
  }
}

.blue-pill {
  background-color: #202a45;
  color: white;
  border: #202a45;
  border-radius: 1em;
  width: 35%;
  text-align: center;
  height: auto;
  line-height: 1.8em !important;
  font-weight: 900;
  padding-top: 0.5em;
  padding-bottom: 0.1em;
}

@media (max-width: 450px) {
  .blue-pill {
    line-height: 1.5em !important;
    height: 24%;
  }
}

.banner {
  background-size: cover;
  position: fixed;
  right: 0;
  height: 100%;
  top: -2em;
  width: 30rem;
  min-width: 20rem;
  max-width: 20rem;
  background-position: center;

  @media (min-width: 1375px) {
    max-width: 22rem;
  }

  @media (max-width: 778px) {
    //establecer minimo
    max-width: 100%;
    width: auto;
    height: auto;
    background-size: contain;
    position: initial;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.bannerContent {
  margin-top: 40vh;
  color: #fff;
  padding-bottom: 1.75em;

  p {
    padding-top: 3.5em;
    font-size: 1em;
  }

  button {
    width: 10em;
    height: 2.5em;
    background-color: #00efa0;
    color: #202945;
    border: none;
    font-weight: bold;
    border-radius: 0.6em;
  }

  h1 {
    font-size: 1.75em;
    font-weight: bold;
  }

  h2 {
    font-size: 1em;
  }

  input {
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
  }

  @media (max-height: 760px) and (min-width: 779px) {
    margin-top: 42vh !important;
  }

  @media (max-height: 630px) and (min-width: 779px) {
    margin-top: 36vh !important;
  }

  @media (min-width: 778px) {
    margin-top: 52vh;
  }

  @media (min-width: 672px) and (max-width: 778px) {
    margin-top: 0px;
    color: #ffffff;
    width: auto;
    display: inline-flex;

    .rowRigth {
      margin-top: 0.75em;
      width: -webkit-fill-available;
      height: 100%;
      padding-bottom: 1em;
      margin-left: 10em;
    }

    .rowLeft {
      margin-top: 3em;
      width: 50%;
      margin-left: 1.5em;
    }

    p {
      width: 100%;
      padding: 0;
      font-size: 87%;
      text-align: left;
      margin-bottom: 0;
      margin-top: 1em;
    }

    button {
      width: 10em;
      height: 1.75em;
      background-color: #00efa0;
      color: #202945;
      border: none;
      font-weight: bold;
      margin-left: 0;
      border-radius: 0.6em;
      font-size: x-small;
      margin-bottom: 0.8em;
    }

    h1 {
      width: 96%;
      font-size: 1.5em;
      font-weight: bold;
    }

    h2 {
      display: none;
    }

    &.email {
      padding-left: 0;
    }

    .col-form-label {
      font-size: smaller;
    }
  }

  @media (min-width: 575px) and (max-width: 672px) {
    margin-top: 0px;
    color: #ffffff;
    width: min-content;
    display: inline-flex;

    .rowRigth {
      margin-top: 0.75em;
      width: -webkit-fill-available;
      height: 100%;
      padding-bottom: 1em;
      margin-left: 16em;
    }

    .rowLeft {
      margin-top: 3em;
      width: 30%;
      margin-left: 1.5em;
    }

    p {
      width: 100%;
      padding: 0;
      font-size: 78%;
      text-align: left;
      margin-bottom: 0;
      margin-top: 1em;
    }

    button {
      width: 10em;
      height: 1.75em;
      background-color: #00efa0;
      color: #202945;
      border: none;
      font-weight: bold;
      margin-left: 0;
      border-radius: 0.6em;
      font-size: x-small;
      margin-bottom: 0.8em;
    }

    h1 {
      width: 241%;
      font-size: 1.5em;
      font-weight: bold;
    }

    h2 {
      display: none;
    }

    &.email {
      padding-left: 0;
    }

    .col-form-label {
      font-size: x-small;
      padding-right: 5em;
    }

    .form-group {
      margin-bottom: 1rem;
      display: inline-flex;
    }

    input {
      width: 109%;
    }
  }

  @media (min-width: 500px) and (max-width: 575px) {
    margin-top: 0px;
    color: #ffffff;
    width: min-content;
    display: inline-flex;

    .rowRigth {
      margin-top: 0.75em;
      width: -webkit-fill-available;
      height: 100%;
      padding-bottom: 1em;
      margin-left: 13em;
    }

    .rowLeft {
      margin-top: 3em;
      width: 30%;
      margin-left: 1.5em;
    }

    p {
      width: 171% !important;
      padding: 0;
      font-size: 67%;
      text-align: left;
      margin-bottom: 0;
      margin-top: 26px;
    }

    button {
      width: 10em;
      height: 1.75em;
      background-color: #00efa0;
      color: #202945;
      border: none;
      font-weight: bold;
      margin-left: 0;
      border-radius: 0.6em;
      font-size: x-small;
      margin-bottom: 1.2em;
    }

    h1 {
      width: 241%;
      font-size: 1.5em;
      font-weight: bold;
      margin-top: 1em;
    }

    h2 {
      display: none;
    }

    &.email {
      padding-left: 0;
    }

    .col-form-label {
      font-size: xx-small;
      padding-right: 0em;
    }

    .form-group {
      margin-bottom: 1rem;
      display: inline-flex;
    }

    input {
      width: 187%;
    }

    .form-control {
      height: calc(1.5em + 0.75rem + -16px);
      font-size: 0.8rem;
      margin-top: 1em;
    }
  }

  @media (min-width: 430px) and (max-width: 500px) {
    margin-top: 0px;
    color: #ffffff;
    width: min-content;
    display: inline-flex;

    .rowRigth {
      margin-top: 0.75em;
      width: -webkit-fill-available;
      height: 100%;
      padding-bottom: 1em;
      margin-left: 9em;
    }

    .rowLeft {
      margin-top: 3em;
      width: 30%;
      margin-left: 1.5em;
    }

    p {
      width: 171% !important;
      padding: 0;
      font-size: 67%;
      text-align: left;
      margin-bottom: 0;
      margin-top: 26px;
    }

    button {
      width: 10em;
      height: 1.75em;
      background-color: #00efa0;
      color: #202945;
      border: none;
      font-weight: bold;
      margin-left: 0;
      border-radius: 0.6em;
      font-size: x-small;
      margin-bottom: 1.2em;
    }

    h1 {
      width: 218%;
      font-size: 1.3em;
      font-weight: bold;
      margin-top: 1em;
    }

    h2 {
      display: none;
    }

    &.email {
      padding-left: 0;
    }

    .col-form-label {
      font-size: xx-small;
      padding-right: 0em;
    }

    .form-group {
      margin-bottom: 0rem;
      display: inline-flex;
    }

    input {
      width: 187%;
    }

    .form-control {
      height: calc(1.5em + 0.75rem + -16px);
      font-size: 0.8rem;
      margin-top: 1em;
    }
  }

  @media (min-width: 372px) and (max-width: 430px) {
    margin-top: 0px;
    color: #ffffff;
    width: min-content;
    display: inline-flex;

    .rowRigth {
      margin-top: 0.75em;
      width: -webkit-fill-available;
      height: 100%;
      padding-bottom: 1em;
      margin-left: 7em;
    }

    .rowLeft {
      margin-top: 3em;
      width: 30%;
      margin-left: 1.5em;
    }

    p {
      width: 171% !important;
      padding: 0;
      font-size: 60%;
      text-align: left;
      margin-bottom: 0;
      margin-top: 26px;
    }

    button {
      width: 9em;
      height: 1.7em;
      background-color: #00efa0;
      color: #202945;
      border: none;
      font-weight: bold;
      margin-left: 0;
      border-radius: 0.6em;
      font-size: xx-small;
      margin-bottom: 1.2em;
    }

    h1 {
      width: 200%;
      font-size: 0.9em;
      font-weight: bold;
      margin-top: 1em;
    }

    h2 {
      display: none;
    }

    &.email {
      padding-left: 0;
    }

    .col-form-label {
      font-size: 60%;
      padding-right: 0em;
    }

    .form-group {
      margin-bottom: 0rem;
      display: inline-flex;
    }

    input {
      width: 187%;
    }

    .form-control {
      height: calc(1.5em + 0.75rem + -16px);
      font-size: 0.8rem;
      margin-top: 1em;
    }
  }

  @media (min-width: 346px) and (max-width: 372px) {
    margin-top: 0px;
    color: #ffffff;
    width: min-content;
    display: inline-flex;

    .rowRigth {
      margin-top: 0.75em;
      width: -webkit-fill-available;
      height: 100%;
      padding-bottom: 1em;
      margin-left: 6em;
    }

    .rowLeft {
      margin-top: 3em;
      width: 30%;
      margin-left: 1.5em;
    }

    p {
      width: 171% !important;
      padding: 0;
      font-size: 55%;
      text-align: left;
      margin-bottom: 0;
      margin-top: 37px;
    }

    button {
      width: 9em;
      height: 1.7em;
      background-color: #00efa0;
      color: #202945;
      border: none;
      font-weight: bold;
      margin-left: 0;
      border-radius: 0.6em;
      font-size: xx-small;
      margin-bottom: 1.2em;
    }

    h1 {
      width: 200%;
      font-size: 0.9em;
      font-weight: bold;
      margin-top: 1em;
    }

    h2 {
      display: none;
    }

    &.email {
      padding-left: 0;
    }

    .col-form-label {
      font-size: 55%;
      padding-right: 0em;
    }

    .form-group {
      margin-bottom: 0rem;
      display: inline-flex;
    }

    input {
      width: 143%;
    }

    .form-control {
      height: calc(1.5em + 0.75rem + -16px);
      font-size: 0.5rem;
      margin-top: 1em;
    }
  }

  @media (max-width: 346px) {
    margin-top: -7px;
    color: #ffffff;
    width: min-content;
    display: inline-flex;

    .rowRigth {
      margin-top: 0.75em;
      width: -webkit-fill-available;
      height: 100%;
      padding-bottom: 1em;
      margin-left: 5em;
    }

    .rowLeft {
      margin-top: 3em;
      width: 30%;
      margin-left: 1.5em;
    }

    p {
      width: 123% !important;
      padding: 0;
      font-size: 50%;
      text-align: left;
      margin-bottom: -5px;
      margin-top: 37px;
    }

    button {
      width: 9em;
      height: 1.7em;
      background-color: #00efa0;
      color: #202945;
      border: none;
      font-weight: bold;
      margin-left: 0;
      border-radius: 0.6em;
      font-size: 50%;
      margin-bottom: 1.2em;
    }

    h1 {
      width: 200%;
      font-size: 0.9em;
      font-weight: bold;
      margin-top: 1em;
    }

    h2 {
      display: none;
    }

    &.email {
      padding-left: 0;
    }

    .col-form-label {
      font-size: 50%;
      margin-left: -1em;
      margin-right: -7px;
    }

    .form-group {
      margin-bottom: 0rem;
      display: inline-flex;
    }

    input {
      width: 94%;
    }

    .form-control {
      height: calc(1.5em + 0.75rem + -16px);
      font-size: 0.4rem;
      margin-top: 1em;
    }
  }
}

.animationReactions {
  pointer-events: none;
  width: 5em;
  z-index: 3;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  right: 0.5em;
  position: fixed;
  bottom: 1rem;
  opacity: 0;

  @keyframes animation {
    0% {
      right: 0%;
      bottom: 18%;
      opacity: 1;
    }

    10% {
      right: 4%;
      bottom: 17%;
    }

    20% {
      right: 6%;
      bottom: 18%;
    }

    30% {
      right: 7%;
      bottom: 24.5%;
    }

    40% {
      right: 7.5%;
      bottom: 31%;
    }

    100% {
      right: 8%;
      bottom: 70%;
    }
  }

  @media (max-height: 800px) {
    @keyframes animation {
      0% {
        right: 0%;
        bottom: 20%;
        opacity: 1;
      }

      10% {
        right: 4%;
        bottom: 19%;
      }

      20% {
        right: 6%;
        bottom: 20;
      }

      30% {
        right: 7%;
        bottom: 26.5%;
      }

      40% {
        right: 7.5%;
        bottom: 33%;
      }

      100% {
        right: 8%;
        bottom: 70%;
      }
    }
  }

  @media (max-height: 750px) {
    @keyframes animation {
      0% {
        right: 0%;
        bottom: 22%;
        opacity: 1;
      }

      10% {
        right: 4%;
        bottom: 21%;
      }

      20% {
        right: 6%;
        bottom: 22%;
      }

      30% {
        right: 7%;
        bottom: 28.5%;
      }

      40% {
        right: 7.5%;
        bottom: 35%;
      }

      100% {
        right: 8%;
        bottom: 60%;
      }
    }
  }

  @media (max-height: 640px) {
    @keyframes animation {
      0% {
        right: 0%;
        bottom: 24%;
        opacity: 1;
      }

      10% {
        right: 4%;
        bottom: 23%;
      }

      20% {
        right: 6%;
        bottom: 24%;
      }

      30% {
        right: 7%;
        bottom: 30.5%;
      }

      40% {
        right: 7.5%;
        bottom: 37%;
      }

      100% {
        right: 8%;
        bottom: 60%;
      }
    }
  }

  @media (max-height: 590px) {
    @keyframes animation {
      0% {
        right: 0%;
        bottom: 26%;
        opacity: 1;
      }

      10% {
        right: 4%;
        bottom: 25%;
      }

      20% {
        right: 6%;
        bottom: 26%;
      }

      30% {
        right: 7%;
        bottom: 32.5%;
      }

      40% {
        right: 7.5%;
        bottom: 39%;
      }

      100% {
        right: 8%;
        bottom: 60%;
      }
    }
  }
}

.tipsPage {
  background-color: #ffffff;
  width: 97%;
  height: auto;
  border-radius: 1.75em;
  text-align: left;
  color: #202945;
  margin: 1em;
  -webkit-box-shadow: 2px 4px 22px 0px rgba(148, 142, 148, 0.63);
  -moz-box-shadow: 2px 4px 22px 0px rgba(148, 142, 148, 0.63);
  box-shadow: 2px 4px 22px 0px rgba(148, 142, 148, 0.63);
  display: flex;
  align-self: center;

  .strong {
    color: #202945 !important;
    font-weight: bold;
  }

  .principalText {
    margin-top: 1em;

    h4 {
      display: inline;
      color: #828797;
      margin-left: 3.45em;
    }
  }

  h1,
  h2 {
    text-align: left;
    margin-left: 2em;
    margin-top: 0.5em;
    color: #202945;
    font-weight: bold;
  }

  p {
    margin-top: 0.5em;
    text-align: justify;
    line-height: 23px;
    font-size: medium;
    margin-left: 4.5em;
    width: 80%;
  }

  .buttons {
    display: flex;
    justify-content: left;

    margin-top: 0.75rem;
    margin-left: 4.6em;
    width: fit-content;
  }

  .image {
    width: 90%;
    background-image: url("../assets/img/pay.png");
    background-size: 45vw;
    background-repeat: no-repeat;
    background-position-x: -5em;

    p {
      width: 35%;
      text-align: center;
      margin-top: 85%;
      font-weight: bold;
      margin-left: 59%;
      font-size: 1.3em;
    }
  }

  .sinpe {
    display: flex;
    flex-direction: row;
  }

  @media (max-height: 860px) {
    align-self: baseline;
  }

  @media (max-width: 540px) {
    h2 {
      text-align: left;
      margin-left: 0em;
      margin-top: 0.5em;
      color: #202945;
      font-weight: bold;
    }
  }

  @media (max-width: 1120px) {
    height: fit-content;

    .image {
      display: none;
    }

    .principalText {
      h4 {
        display: table;
      }
    }
  }

  @media (max-width: 715px) {
    h2 {
      font-size: 1.5rem;
      margin-left: 3.5em !important;
    }
  }

  @media (min-width: 300px) and (max-width: 540px) {
    flex-wrap: wrap;
    height: fit-content;

    .principalText {
      margin-top: 1.75em;

      h4 {
        display: table;
        font-size: 1em;
        margin-left: 3%;
        display: block;
      }
    }

    .buttons {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      margin-left: 3%;

      button {
        width: 10em;
      }
    }

    p {
      font-size: small;
      width: 90%;
      text-align: justify;
      margin-left: 3%;
      margin-bottom: 1.5em;
    }

    h1 {
      text-align: left;
      font-size: 1.9em;
      margin-left: 3%;
    }

    h2 {
      text-align: left;
      margin-left: 3% !important;
    }

    .image {
      display: none;
    }

    .sinpe {
      flex-wrap: wrap;

      margin-left: 2em;
    }
  }

  @media (min-width: 270px) and (max-width: 300px) {
    height: fit-content;

    .principalText {
      margin-top: 1.75em;

      h4 {
        display: table;
        font-size: 1em;
        margin-left: 3%;
        display: block;
      }
    }

    .buttons {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      margin-left: 3%;

      button {
        width: 10em;
      }
    }

    p {
      font-size: small;
      width: 90%;
      text-align: justify;
      margin-left: 3%;
      margin-bottom: 1.5em;
    }

    h1 {
      text-align: left;
      font-size: 1.9em;
      margin-left: 3%;
    }

    h2 {
      text-align: left;
      margin-left: 3% !important;
    }

    .image {
      display: none;
    }

    .sinpe {
      flex-wrap: wrap;

      margin-left: 2em;
    }
  }

  @media (max-width: 270px) {
    height: fit-content;

    .principalText {
      margin-top: 1.75em;

      h4 {
        display: table;
        font-size: 1em;
        margin-left: 3%;
        display: block;
      }
    }

    .buttons {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      margin-left: 3%;

      button {
        width: 10em;
      }
    }

    p {
      font-size: small;
      width: 90%;
      text-align: justify;
      margin-left: 3%;
      margin-bottom: 1.5em;
    }

    h1 {
      text-align: left;
      font-size: 1.9em;
      margin-left: 3%;
    }

    h2 {
      text-align: left;
      margin-left: 3% !important;
    }

    .image {
      display: none;
    }

    .sinpe {
      flex-wrap: wrap;

      margin-left: 2em;
    }
  }
}

#paypal-button-container {
  position: sticky;
}

.tipsBtn {
  z-index: 3;
  position: absolute;
  top: 1rem;
  left: 1rem;

  cursor: pointer;
  height: 5rem;
  width: 5rem;

  display: flex;
  justify-content: center;

  border-radius: 50%;

  border-image: initial;

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    color: #ffffff;
    user-select: none;
  }
}

.animationHidden {
  margin-top: 30em;
}

footer {
  #change-language-btn {
    color: #9ba5c4;
    background-color: $immerso-blue-color;
    border-color: $immerso-blue-color;
  }

  .dropdown-item,
  .dropdown-menu {
    color: #9ba5c4;
    background-color: $immerso-blue-color;
  }

  .dropdown-item:hover {
    background-color: $immerso-blue-color;
    color: #00efa0;
  }

  #language-dropdown {
    display: flex;
    justify-content: center;
    margin-right: 1.5rem;
    //padding-right: 5vh;
    border-right: 1px solid #9ba5c4;
  }
}

.not-clickable {
  pointer-events: none;
}

//This is the survey modal
.smcx-modal {
  max-width: 637px !important;
  height: 501px !important;
  top: 43% !important;

  .smcx-modal-content {
    width: 97% !important;
    height: 80% !important;
  }
}

.tipsPage {
  .tip-amount {
    border: 2px solid $immerso-blue-color;
    border-radius: 10px;
    font-size: 2.5em;
    text-align: center;
    line-height: 2.1em;
    cursor: pointer;
    max-width: fit-content;
  }

  .tip-selected {
    cursor: initial;
    color: white;
    background-color: $immerso-blue-color;
  }

  .tip-amount-container {
    width: 122%;
    height: 5%;
    padding-bottom: 5em;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 319px) {
    display: block;

    &.pl-5 {
      margin-left: -57px !important;
      font-size: 31%;
      display: inline-flex !important;
      padding: 8px;
    }

    .ml-5,
    .mx-5 {
      margin-left: -0.9rem !important;
    }
  }

  @media (min-width: 319px) and (max-width: 360px) {
    display: block;

    &.pl-5 {
      margin-left: -57px !important;
      font-size: 31%;
      display: inline-flex !important;
      padding: 8px;
    }
  }

  @media (min-width: 360px) and (max-width: 470px) {
    display: block;

    &.pl-5 {
      margin-left: -56px !important;
      font-size: 39%;
      display: inline-flex !important;
      padding: 8px;
    }
  }

  @media (min-width: 470px) and (max-width: 715px) {
    display: block;

    &.pl-5 {
      margin-left: 4px !important;
      font-size: 39%;
      display: inline-flex !important;
      padding: 8px;
    }

    .m-0 {
      margin: 0 !important;
      padding-left: 6em;
    }
  }

  @media (min-width: 715px) and (max-width: 820px) {
    display: block;

    &.pl-5 {
      margin-left: -56px !important;
      font-size: 61%;
      display: inline-flex !important;
      padding: 8px;
    }

    .m-0 {
      margin: 0 !important;
      padding-left: 15em;
    }
  }

  @media (min-width: 820px) and (max-width: 900px) {
    max-width: 100%;
    display: block;

    &.pl-5 {
      margin-left: -56px !important;
      font-size: 71%;
      display: inline-flex !important;
      padding: 8px;
    }
  }

  @media (min-width: 900px) {
    &.pl-5 {
      margin-left: 4px !important;
      font-size: 74%;
      display: inline-flex !important;
      padding: 8px;
      width: inherit;
    }
  }

  .col-12 {
    flex: 0 0 50%;
  }

  .col-10 {
    margin-right: 0em;
  }
}

@keyframes timer {
  0% {
    width: 100%;
  }

  to {
    width: 0;
  }
}

.notification-container--bottom-center {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: calc(50% - 175px);
  max-width: 350px;
  bottom: 20px;
}

.notification-container--bottom-full {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  width: 100%;
  min-width: 100%;
  bottom: 0;

  .notification__item {
    margin-bottom: 0;
    border-radius: 0;
  }
}

.notification-container--bottom-left {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  left: 20px;
  bottom: 20px;

  .notification {
    margin-right: auto;
  }
}

.notification-container--bottom-right {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  bottom: 20px;
  right: 20px;

  .notification {
    margin-left: auto;
  }
}

.notification-container--center {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: calc(50% - 175px);
  max-width: 350px;
  top: 20px;
  height: 100%;
  pointer-events: none;
}

.notification-container--top-center {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: calc(50% - 175px);
  max-width: 350px;
  top: 20px;
}

.notification-container--top-full {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  width: 100%;
  min-width: 100%;

  .notification__item {
    margin-bottom: 0;
    border-radius: 0;
  }
}

.notification-container--top-left {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  left: 20px;
  top: 20px;

  .notification {
    margin-right: auto;
  }
}

.notification-container--top-right {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
  right: 20px;
  top: 20px;

  .notification {
    margin-left: auto;
  }
}

.flex-center {
  min-width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: all;
}

.notification-container--mobile-bottom {
  pointer-events: all;
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 20px;
  margin-bottom: -15px;

  .notification {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .notification__item {
    max-width: 100%;
    width: 100%;
  }
}

.notification-container--mobile-top {
  pointer-events: all;
  position: absolute;
  right: 20px;
  left: 20px;
  top: 20px;

  .notification {
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .notification__item {
    max-width: 100%;
    width: 100%;
  }
}

.notification__item--default {
  background-color: #007bff;
  border-left: 8px solid #0562c7;

  .notification__timer {
    background-color: #007bff;
  }

  .notification__timer-filler {
    background-color: #fff;
  }

  .notification__close {
    background-color: #007bff;
  }
}

.notification__item--success {
  background-color: #28a745;
  border-left: 8px solid #1f8838;

  .notification__timer {
    background-color: #28a745;
  }

  .notification__timer-filler {
    background-color: #fff;
  }

  .notification__close {
    background-color: #28a745;
  }
}

.notification__item--danger {
  background-color: #dc3545;
  border-left: 8px solid #bd1120;

  .notification__timer {
    background-color: #dc3545;
  }

  .notification__timer-filler {
    background-color: #fff;
  }

  .notification__close {
    background-color: #dc3545;
  }
}

.notification__item--info {
  background-color: #17a2b8;
  border-left: 8px solid #138b9e;

  .notification__timer {
    background-color: #17a2b8;
  }

  .notification__timer-filler {
    background-color: #fff;
  }

  .notification__close {
    background-color: #17a2b8;
  }
}

.notification__item--warning {
  background-color: #eab000;
  border-left: 8px solid #ce9c09;

  .notification__timer {
    background-color: #eab000;
  }

  .notification__timer-filler {
    background-color: #fff;
  }

  .notification__close {
    background-color: #eab000;
  }
}

.notification__item--awesome {
  background-color: #685dc3;
  border-left: 8px solid #4c3fb1;

  .notification__timer {
    background-color: #685dc3;
  }

  .notification__timer-filler {
    background-color: #fff;
  }

  .notification__close {
    background-color: #685dc3;
  }
}

.notifications-component {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.notification__item {
  display: flex;
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.notification__timer {
  width: 100%;
  margin-top: 10px;
  height: 3px;
  border-radius: 5px;
}

.notification__timer-filler {
  height: 3px;
  border-radius: 5px;
}

.notification__title {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.notification__message {
  color: #fff;
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}

.notification__content {
  padding: 8px 15px;
  display: inline-block;
  width: 100%;
}

.notification__close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;

  &:after {
    content: "\D7";
    position: absolute;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 12px;
    left: 50%;
    top: 50%;
  }
}

.loading-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #a5c4cb;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  flex-direction: column;

  img {
    width: 250px;
  }
}

.btn-tooltip {
  &:hover {
    background-color: transparent;
    color: transparent;
  }
}

// #jitsi-container-id {
//   pointer-events: none;
//   z-index: 1;
//   position: fixed;
//   padding-top: 15%;
//   width: 15%;
//   margin-left: 54em;
//   margin-top: 1em;

//   @media screen and (max-width: 640px) {
//     width: 15%;
//     margin-left: 54em;
//     margin-top: 1em;
//     padding-top: 15%;
//   }

//   iframe {
//     pointer-events: all;
//     position: fixed;
//     top: 0;
//     right: 0;
//     margin: 1em;
//     border: 0.5em rgba(0, 240, 161, 0.8) groove !important;
//     border-radius: 0.7em;
//     max-height: 265px !important;
//     max-width: 355px !important;
//     height: 20vw !important;
//     width: 27.5vw !important;
//     user-select: none;
//   }
// }

#video-hotspot-display {
  iframe {
    pointer-events: all;
  }
}

iframe {
  pointer-events: none;
}

#video-hotspot-display {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  padding: 10px;
  display: none;
}

.portraitMode {
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  display: block;
  background: $immerso-blue-color;
  text-align: center;
  color: white;

  img {
    width: 7em;
    margin-top: 60%;
  }

  h1 {
    font-size: larger;
    text-align: center;
    margin-top: 2em;
  }

  @media (min-width: 700px) and (max-width: 1132px) {
    img {
      width: 12em;
      margin-top: 45%;
    }

    h1 {
      font-size: 3em;
      text-align: center;
      margin-top: 2em;
    }
  }

  @media (max-width: 700px) {
    img {
      width: 7em;
      margin-top: 60%;
    }

    h1 {
      font-size: 2em;
      text-align: center;
      margin-top: 2em;
    }
  }
}

.moveImageTutorial {
  pointer-events: none;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  user-select: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .background {
    z-index: 2;
    position: absolute;
    width: 35em;
    pointer-events: none;
    margin: auto;
    opacity: 0.75;
  }

  .move-image {
    z-index: 3;
    width: 10em;
    pointer-events: none;
  }

  .text-move-image {
    z-index: 3;
    font-size: 2em;
    text-align: center;
    margin-top: 1em;
    font-weight: 900;
  }
}

.xengine-modal-guided-session {
  transition: 0.3s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -10%);
  z-index: 1000;
  width: 25rem;
  height: 15rem;
  vertical-align: middle;
  top: 30%;
  background-color: #1b3048;
  border-radius: 30px;
  padding: 1rem 2rem 2rem 2rem;
  text-align: center;
  color: #ffffff;
  font-size: medium;

  .btn {
    background-color: #00f0a1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 15em;
    margin: 1.5rem auto 0rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    text-align: center;
    color: #1b3048;

    -moz-border-radius: 50% / 250px;
    -webkit-border-radius: 40% / 250px;
    border-radius: 50% / 250px;
  }

  .close-btn {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    font-size: 1rem;
    height: 1.7rem;
    width: 1.7rem;
    color: #1b3048;
    padding: 0.5rem 0.5rem;
    -webkit-border-radius: 25%;
    -moz-border-radius: 25%;
    border-radius: 100%;
    background: #ffffff;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    display: flex;
    /* or inline-flex */
    align-items: center;
    justify-content: center;
  }
}

.lowBrightness {
  filter: brightness(50%);
  pointer-events: none !important;
}

.xengine-modal-trivia-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .trivia-nav {
    background-color: #0c142b;
    height: 3rem;
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    h3 {
      color: #ffffff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
      font-size: 1.2rem;

      @media (max-height: 620px), (max-width: 420px) {
        left: 17px;
      }
    }

    @media (max-height: 620px), (max-width: 420px) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .xengine-modal-trivia {
    pointer-events: all;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    background-color: #e9e9e9;
    border-radius: 30px;
    padding: 0 0 2rem 0;
    color: $immerso-blue-color;
    font-size: medium;
    user-select: none;

    .containerTriviaButtons {
      text-align-last: right;
      padding-right: 2rem;

      button:first-child {
        margin-right: 1rem;
      }

      @media (max-height: 620px), (max-width: 420px) {
        text-align-last: center;
        padding: 0%;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(255, 255, 255, 0.5);
        bottom: 0;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 10px 0;
      }
    }

    &.success {
      // background-color: #74ffba;
    }

    &.fail {
      // background-color: #ff7474;
    }

    @media (max-height: 620px), (max-width: 420px) {
      padding: 0 0 1rem 0;
      border-radius: 10px;
      height: auto;
    }

    h1 {
      font-size: x-large;
      font-weight: 600;
      margin: 0 2rem 2rem 0rem;

      @media (max-height: 620px), (max-width: 420px) {
        font-size: 1.2rem;
        margin: 0 0 3rem 0;
      }
    }

    h3 {
      font-size: medium;
      font-weight: 600;
      margin: 0 2rem 2rem 0;

      @media (max-height: 620px), (max-width: 420px) {
        font-size: 1.2rem;
        margin: 0 0.5rem 0.5rem 0.5rem;
      }
    }

    .close-button {
      border: 2px solid white;
      background-color: transparent;
      color: white;
      -webkit-border-radius: 25%;
      -moz-border-radius: 25%;
      border-radius: 100%;
      position: absolute;
      top: 4px;
      right: 34px;
      bottom: 4px;
      width: 34px;
      height: 34px;
      font-size: large;

      @media (max-height: 620px), (max-width: 420px) {
        width: 25px;
        height: 25px;
        padding: 0 4px;
        font-size: small;
        top: 8px;
        right: 17px;
      }
    }

    & .imageTrivia {
      height: auto;
      width: 100%;
      border-radius: 8px;

      @media (max-width: 900px) {
        height: 20vh;
      }
    }

    .containerTrivia {
      display: flex;
      user-select: none;
      justify-content: center;
      padding-top: 2rem;

      @media (max-width: 900px) {
        display: block;
        text-align: center;
      }

      & .triviaQuestion {
        margin-left: 3rem;
        width: 50%;

        @media (max-height: 620px), (max-width: 420px) {
          margin: 0 auto;
          width: 85%;
        }
      }

      & .triviaOptions {
        margin-right: 2rem;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: $immerso-blue-color;
        align-self: center;
        user-select: none;
        align-items: center;
        justify-content: end;
        overflow: auto;
        width: 50%;

        @media (max-height: 620px), (max-width: 420px) {
          margin: 0 auto;
          width: 100%;
          justify-content: center;
        }

        &.showing-result {
          .option {
            pointer-events: none;

            &.selected {
              opacity: 1;
              color: $immerso-blue-color;
            }

            &.right {
              background-color: #6bbd69;
              border-color: #6bbd69;
              color: #ffffff;
            }

            &.wrong {
              background-color: #f93e45;
              border-color: #f93e45;
              color: #ffffff;
            }
          }
        }

        &.showing-result-text {
          .option {
            opacity: 0 !important;
            pointer-events: none;
          }

          .result-text {
            display: flex !important;
          }
        }

        .result-text {
          position: absolute;
          width: 100%;
          height: 100%;
          display: none;
          justify-content: center;
          align-items: center;
        }

        & .rowOptions {
          display: flex;
          width: 100%;
          height: 50%;
          padding-bottom: 1em;
        }

        & .option {
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: fit-content;
          margin: 1em;
          position: relative;
          padding: 0.5rem;
          border-radius: 8px;
          cursor: pointer;
          background-color: #fff;
          min-width: 6.5em;
          min-height: 8em;
          width: 20em;
          max-height: 18rem;

          .check-icon {
            border-radius: 2px;
            color: #6bbd69;
            background-color: #ffffff;
            width: 1.8rem;
            height: 1.8rem;
            text-align: center;
            margin: auto;
          }

          .wrong-icon {
            border-radius: 2px;
            color: #f93e45;
            background-color: #ffffff;
            width: 1.8rem;
            height: 1.8rem;
            text-align: center;
            margin: auto;
          }

          .absoluteIcon {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
          }

          @media (max-width: 1700px) {
            width: 12em;
          }

          @media (max-width: 1300px) {
            width: 8em;
          }

          &:hover,
          &.selected {
            background-color: #0c142b;
            color: #fff;
            border: solid 3px $immerso-blue-color;
          }

          @media (max-width: 640px) {
            padding: 0.3rem;
            margin: 0.1rem;
          }

          .selectIcon {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0.3rem;
            width: 1.5rem;
            pointer-events: none;

            @media (max-width: 640px) {
              width: 1rem;
            }
          }

          label {
            width: 100%;
            font-size: calc(100%);
            overflow-wrap: normal;
            margin: 0;
            pointer-events: none;
            text-align: center;
          }

          .MarginAuto {
            margin: auto;
          }

          .trivia-image {
            min-height: 2em;
            width: 100%;
            border-radius: 8px;
          }

          @media (max-height: 620px), (max-width: 420px) {
            max-height: 16rem;
            height: 13rem;
            width: 85%;
          }
        }

        .fourAnswers {
          @media (max-height: 620px), (max-width: 420px) {
            width: 42%;
            height: 5rem;
          }
        }

        .fourAnswersTexts {
          @media (max-height: 620px), (max-width: 420px) {
            height: 18rem;
          }
        }

        @media (max-height: 620px), (max-width: 420px) {
          margin-top: 0.5rem;
          padding-bottom: 3rem;
        }
      }
    }

    @media (max-width: 900px) {
      .withImage {
        max-height: 28vh;
      }

      .withoutImage {
        max-height: 100%;
        height: 38rem;

        height: 100%;
        max-height: 50vh;
      }
    }

    .result-text {
      width: 100%;
      height: 2em;
      font-size: 1.2em;
      font-weight: 400;
      text-align: center;
      overflow: auto;
    }

    .btnResponse {
      text-align-last: center;
      width: 12em;
      height: 2em;
      font-size: x-large;
      border-radius: 2em;
      background-color: #00baa8;
      border: none;
      font-weight: 700;
      color: #ffffff;
      user-select: none;
      margin-top: 2rem;

      &.response {
        // background-color: #ffffff;
        &:hover {
          // background-color: #e2e2e2;
        }
      }

      &:hover {
        background-color: #008275;
      }

      @media (max-height: 620px), (max-width: 420px) {
        margin-top: 0.3rem;
        width: 90%;
        margin: 0 5%;
        font-size: medium;
      }

      @media (max-height: 620px), (max-width: 420px) {
        width: 40%;
        margin: 0 1%;
      }
    }
  }
}

.xengine-modal-trivia-answer {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 30rem;
  background-color: #fff;

  pointer-events: all;
  background-color: #e9e9e9;
  border-radius: 30px;
  padding: 0 0 2rem 0;
  color: $immerso-blue-color;
  font-size: medium;
  user-select: none;

  .triviaAnswerContainer {
    padding: 2rem;
    text-align: center;

    p {
      width: 100%;
    }
  }

  .btnResponse {
    text-align-last: center;
    width: 12em;
    height: 2em;
    font-size: medium;
    border-radius: 2em;
    background-color: #00baa8;
    border: none;
    font-weight: 700;
    color: #ffffff;
    user-select: none;
    margin-top: 2rem;

    &:hover {
      background-color: #008275;
    }

    @media (max-height: 620px), (max-width: 420px) {
      width: 12em;
      text-align: justify;
    }
  }

  @media (max-height: 620px), (max-width: 420px) {
    width: 20rem;
  }
}

.xengine-modal-share-menu {
  transition: 0.3s;
  position: relative;
  width: 15rem;
  height: 6rem;
  margin: 0 auto;
  vertical-align: middle;
  top: 40%;
  background-color: #1b3048;
  border-radius: 30px;
  padding: 1rem 2rem 2rem 2rem;
  text-align: center;
  z-index: 4;
  color: #ffffff;
  font-size: medium;
  display: flex;
  justify-content: space-between;
  visibility: hidden;

  .close-btn {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    font-size: 1rem;
    height: 1.7rem;
    width: 1.7rem;
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    -khtml-user-select: none;
    -webkit-user-select: none;
    display: flex;
    /* or inline-flex */
    align-items: center;
    justify-content: center;
  }
}

.share-Whatsapp {
  position: relative;
  padding: 5px;
  margin: auto;
  top: 15%;
}

.share-Facebook {
  position: relative;
  padding: 5px;
  margin: auto;
  top: 15%;
}

.call-to-action-contanier {
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  bottom: 0rem;
  margin-bottom: 2rem;
  align-items: center;
  z-index: 4;

  @media (max-width: 823px) {
    margin-bottom: 1rem;
  }

  .call-to-action {
    pointer-events: all;
    text-align: center;
    border-radius: 5rem;
    padding: 1rem;
    background-color: #00f0a1;
    font-size: 2rem;
    font-weight: bold;
    color: #1b3048;

    &:hover {
      text-decoration: none;
      transform: scale(1.1);
    }

    @media (max-width: 823px) {
      font-size: 1.5rem;
      padding: 0.5rem;
    }

    .call-to-action-promotional {
      font-size: 0.75rem;
      font-weight: normal;
    }
  }
}

.navigation-menu {
  height: 100%;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  left: -21em;
  transition: left 1s;

  &.open {
    left: 0;
  }

  .locations {
    margin-top: 1em;
    overflow: auto;

    .location-nav {
      width: 13em;
      font-size: 1.5em;
      padding: 0.3em 1em 0.3em 1em;
      margin: 0.1em 0 0.1em 0;

      &:hover {
        filter: brightness(0.6);
      }
    }
  }

  .nav-button {
    position: absolute;
    top: 1.8rem;
    right: -2em;
    font-size: 3em;
    padding: 0.3em 0.3em 0.3em 0.3em;
    border-radius: 13px 13px 13px 13px;
    z-index: 900;

    &.fa-chevron-left {
      padding: 0.3em 0.5em 0.3em 0.5em;
    }

    @media (max-width: 800px) {
      right: -3em;
    }

    @media (max-width: 500px) {
      font-size: 2em;
      right: -3em;
    }
  }
}

.sponsor-panel {
  min-height: 103px;
  width: 19.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;

  //Horizontal Position bottom right
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 25rem;

  .title {
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    margin: 0.5em;

    //New Style for visible
    color: white;
    font-weight: bold;

    @media (max-width: 600px) {
      font-size: 0.8em;
    }
  }

  .sponsors-tab {
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    color: #1b3048;
    text-align: center;
    pointer-events: none;
  }

  .sponsors {
    background-color: #fff;
    border-radius: 13px 13px 13px 13px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: auto;

    .sponsor {
      cursor: alias;
      text-align: center;

      img {
        margin: 0.5rem;
        width: 4rem;
        height: auto;
        min-width: 3rem;
        min-height: 3rem;

        @media (max-width: 600px) {
          width: 3rem;
          min-width: 2rem;
          min-height: 2rem;
        }
      }
    }

    @media (max-width: 1000px) {
      display: initial;
      text-align: center;
    }

    @media (max-width: 600px) {
    }
  }

  @media (min-width: 950px) and (max-width: 1000px) {
    .sponsors-tab {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 950px) {
    .sponsors-tab {
      font-size: 1rem;
    }
  }

  @media (max-width: 1000px) {
    bottom: 8rem;
    right: 1rem;
    width: 10rem;
  }

  @media (max-width: 600px) {
    width: 4rem;
  }
}

.tour-filter {
  width: 100%;
  display: flex;
  justify-content: center;

  .fields {
    width: 100%;
    max-width: 1140px;

    select {
      font-family: "Eina02-Bold";
      font-weight: 100;
      font-size: 26px;
      color: $immerso-blue-color;
      padding: 0.3em;
      margin-left: 1rem;
      border: 3px solid #28e89d;
      border-radius: 0.5em;
    }
  }
}

.a-modal {
  display: none !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hotspot-modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  .hotspot-modal-overlay {
    cursor: pointer;
    pointer-events: all;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000008c0;
    transition: background-color 0.5s ease-in-out;
    z-index: 1001;
    transform: scale(1);

    &.closed {
      pointer-events: none;
      background-color: #00000800;
    }
  }

  .hotspot-modal {
    pointer-events: none;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1005;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: transform 0.5s ease-in-out;

    &.closed {
      transform: scale(0);
    }

    .close-btn {
      cursor: pointer;
      position: absolute;
      font-size: 5em;
      top: 0.2em;
      right: 0.2em;
      z-index: 1003;

      @media (max-height: 620px), (max-width: 620px) {
        font-size: 3em;
        top: 0.5em;
        right: 0.5em;
      }
    }

    .hotspot-content {
      pointer-events: all;
      width: auto;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 8, 1);
      border-radius: 3.5em;
      max-height: 100%;
      max-width: 100%;

      &.vertical {
        max-width: 90%;
        height: 90%;
      }

      &.horizontal {
        max-height: 90%;
        width: 90%;
      }

      img {
        border-radius: 3.5em;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .hotspot-info {
        border-radius: 0 3.5em 0 3.5em;
        position: absolute;
        max-width: 50%;
        max-height: 100%;
        color: white;
        top: 0;
        right: 0;
        padding: 1em;
        text-align: center;
        overflow-y: auto;
        background: rgba(0, 0, 8, 0.7);

        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        @media (max-height: 620px), (max-width: 620px) {
          padding: 1.5em;
        }

        .hotspot-title {
          font-size: 2em;
          font-family: "Eina02-Bold";

          @media (max-height: 620px), (max-width: 620px) {
            font-size: 2.2em;
          }
        }

        .hotspot-description {
          font-size: 1.1em;
        }
      }
    }
  }
}

.a-enter-ar {
  right: 10px !important;
  bottom: 60px !important;
}
